import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ReportGeneratorMsService {

  //url = 'http://localhost:8080/api/v1/api-report-generator-ms/report';
  url = 'https://api-gateway-prod.ipt.pe/api/v1/api-report-generator-ms/report';

  constructor(private http: HttpClient) { }

  generateReports(formData: FormData) {
    // this.url += "/report";
    // let httpParams = new HttpParams().append("entelReport", form.value.entelReport).append("movistarReport", form.value.movistarReport);
    // this.http.post(this.url, form.value.file, { params: httpParams });
    return this.http.post(this.url, formData, { responseType: 'blob' });
  }
}

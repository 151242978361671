<div class="card main" style="width: 30rem;">
    <img src="../../../assets/reporte-fondo-ipt.jpg" class="card-img-top" alt="reportes-fondo">
    <div style="margin: 10px 20px;">
        <div class="div-message-progress">
            <mat-icon aria-hidden="false" [ngStyle]="{'color': estadoProceso.color}" class="icon-message-progress">
                {{estadoProceso.icon}}</mat-icon>
            <h5 class="h-message-progress" [ngStyle]="{'color': estadoProceso.color}">{{estadoProceso.mensaje}}
            </h5>
        </div>
        <mat-progress-bar class="example-margin" [color]="estadoProceso.colorProgressBar"
            [mode]="estadoProceso.modelProgressBar" [value]=estadoProceso.valueProgressBar>
        </mat-progress-bar>
    </div>
    <div class="card-body">
        <h5 class="card-title" style="font-weight: bold;
        font-size: 12px;">Generador de reportes</h5>
        <!-- <form [formGroup]="reportForm" (ngSubmit)="onSubmit()"> -->
        <div>
            <label>Subir el archivo</label>
            <div class="custom-file mb-3">
                <input type="file" id="file" class="custom-file-input" (change)="fileChange($event)">
                <label class="custom-file-label" for="documentReport">{{obtenerMensaje()}}</label>
            </div>
            <label>Escoja los reportes a generar</label>
            <!--<div class="form-group form-check">-->
            <!-- <input type="checkbox" class="form-check-input" id="entelReport" formControlName="entelReport"> -->
            <!--    <input type="checkbox" class="form-check-input" id="entelReport" [(ngModel)]="entelReport">
                <label class="form-check-label" for="entelReport">Reporte entel</label>
            </div> -->
            <!--<div class="form-group form-check">-->
                <!-- <input type="checkbox" class="form-check-input" id="movistarReport" formControlName="movistarReport"> -->
            <!--    <input type="checkbox" class="form-check-input" id="movistarReport" [(ngModel)]="movistarReport">
                <label class="form-check-label" for="movistarReport">Reporte movistar</label>
            </div>-->
            <div class="form-group form-check">                
                <input type="checkbox" class="form-check-input" id="entelCoverage" [(ngModel)]="entelCoverage">
                <label class="form-check-label" for="entelCoverage">RAN Verificar cobertura ENTEL</label>
            </div>
            <div class="form-group form-check">                
                <input type="checkbox" class="form-check-input" id="tdpCoverage" [(ngModel)]="tdpCoverage">
                <label class="form-check-label" for="tdpCoverage">RAN Verificar cobertura TDP</label>
            </div>
            <div class="form-group form-check">                
                <input type="checkbox" class="form-check-input" id="installation" [(ngModel)]="installation">
                <label class="form-check-label" for="installation">RAN Reporte de instalación</label>
            </div>
            <button type="submit" class="btn btn-primary" [disabled]="!validacionProcesamiento? 'disabled': null"
                (click)="procesarArchivo()">Enviar</button>
            <!-- <button mat-raised-button color="primary" [disabled]="!validacionProcesamiento" type="button" -->
            <!-- >Procesar</button> --> 
        </div>
        <!-- </form> -->
    </div>
</div>
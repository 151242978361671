import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './components/main/main.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { ReportFormComponent } from './components/report-form/report-form.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {BulkLoadProjectFormComponent} from './components/bulk-load-project-form/bulk-load-project-form.component'; 

// Material

import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatToolbarModule } from '@angular/material/toolbar';


const appRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'main/report'
  },
  {
    path: 'main',
    component: MainComponent,
    children: [
      {
        path: 'report',
        component: ReportFormComponent
      }
    ]
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'main/bulkloadproject'
  },
  {
    path: 'main',
    component: MainComponent,
    children: [
      {
        path: 'bulkloadproject',
        component: BulkLoadProjectFormComponent
      }
    ]
  },
];

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    NavbarComponent,
    ReportFormComponent,
    BulkLoadProjectFormComponent
  ],
  imports: [
    MatIconModule,
    MatProgressBarModule,
    MatToolbarModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(appRoutes),
    BrowserAnimationsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

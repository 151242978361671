import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class BulkLoadProjectMsService {
  
  url = 'https://api-gateway-prod.ipt.pe/api/v1/api-report-generator-ms/bulk-load/project';

  constructor(private http: HttpClient) { }

  bulkLoadProjectts(formData: FormData) {
    return this.http.post(this.url, formData, {observe: 'response'});
  }
}

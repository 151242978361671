<div class="card main" style="width: 30rem;">
    <img src="../../../assets/reporte-fondo-ipt.jpg" class="card-img-top" alt="reportes-fondo">
    <div style="margin: 10px 20px;">
        <div class="div-message-progress">
            <mat-icon aria-hidden="false" [ngStyle]="{'color': estadoProceso.color}" class="icon-message-progress">
                {{estadoProceso.icon}}</mat-icon>
            <h5 class="h-message-progress" [ngStyle]="{'color': estadoProceso.color}">{{estadoProceso.mensaje}}
            </h5>
        </div>
        <mat-progress-bar class="example-margin" [color]="estadoProceso.colorProgressBar"
            [mode]="estadoProceso.modelProgressBar" [value]=estadoProceso.valueProgressBar>
        </mat-progress-bar>
    </div>
    <div class="card-body">
        <h5 class="card-title" style="font-weight: bold;
        font-size: 12px;">Carga Masiva de proyectos</h5>
        <!-- <form [formGroup]="reportForm" (ngSubmit)="onSubmit()"> -->
        <div>
            <label>Subir el archivo</label>
            <div class="custom-file mb-3">
                <input type="file" id="file" class="custom-file-input" (change)="fileChange($event)">
                <label class="custom-file-label" for="documentReport">{{obtenerMensaje()}}</label>
            </div>                                            
            <button type="submit" class="btn btn-primary" [disabled]="!validacionProcesamiento? 'disabled': null"
                (click)="procesarArchivo()">Enviar</button>            
        </div>    
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { BulkLoadProjectMsService } from 'src/app/services/bulk-load-project/bulk-load-project-ms.service'
import { ActivatedRoute } from '@angular/router';

interface EstadoProceso {
  mensaje: string;
  color: string;
  icon: string;
  modelProgressBar: string;
  valueProgressBar: string;
  colorProgressBar: string;
}

// Constantes de progress Bar para procesamiento de archivos

const inicio: EstadoProceso = {
  mensaje: 'Se debe seleccionar un archivo',
  color: '#3f51b5',
  icon: 'warning',
  modelProgressBar: 'determinate',
  valueProgressBar: '100',
  colorProgressBar: 'primary',
};

const enProceso: EstadoProceso = {
  mensaje: 'El archivo está siendo procesado, espere un momento ...',
  color: '#3f51b5',
  icon: 'science',
  modelProgressBar: 'query',
  valueProgressBar: '100',
  colorProgressBar: 'accent',
};
const doneProceso: EstadoProceso = {
  mensaje: 'El archivo se procesó correctamente',
  color: '#45af7b',
  icon: 'done',
  modelProgressBar: 'determinate',
  valueProgressBar: '100',
  colorProgressBar: 'accent',
};
const errorProceso: EstadoProceso = {
  mensaje: 'Hubo un problema en el procesamiento del archivo',
  color: 'red',
  icon: 'error_outline',
  modelProgressBar: 'determinate',
  valueProgressBar: '100',
  colorProgressBar: 'warn',
};

@Component({
  selector: 'app-report-form',
  templateUrl: './bulk-load-project-form.component.html',
  styleUrls: ['./bulk-load-project-form.component.css']
})
export class BulkLoadProjectFormComponent implements OnInit {

  fileToUpload: File;
  estadoProceso: EstadoProceso = inicio;
  validacionProcesamiento = false;
  idUser: Number = null;

  constructor(private readonly route: ActivatedRoute,
    private bulkLoadProjectMS: BulkLoadProjectMsService) {                  
     }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.idUser = params['idUser'] != null ? params['idUser'] : '';
    })
   }

  // File Interaction
  // tslint:disable-next-line: typedef
  fileChange(element) {
    this.fileToUpload = element.target.files[0];
    this.validarProcesamiento();
  }

  validarProcesamiento() {
    this.validacionProcesamiento = this.fileToUpload === undefined ? false : true;
  }

  obtenerMensaje(): string {
    let mensaje = 'Seleccione un archivo ...';
    if (this.fileToUpload !== undefined) {
      mensaje = this.fileToUpload.name;
    }
    return mensaje;
  }

  procesarArchivo() {  
    const formData: FormData = new FormData();
    formData.append('file', this.fileToUpload);
    formData.append('idUser', String(this.idUser));      
    this.estadoProceso = enProceso;
    this.validacionProcesamiento = false;
    this.bulkLoadProjectMS.bulkLoadProjectts(formData).subscribe(data => {
      if (data.status == 200){
        this.estadoProceso = doneProceso;
        this.validarProcesamiento();
      }                           
    }, error => {
      this.estadoProceso = errorProceso;
      this.validarProcesamiento();
    });
  }
}

<mat-toolbar class="mat-elevation-z8">
    <mat-toolbar-row>
        <img class="logo" src="../../assets/logo-ipt.png">
        <!-- <div>
        <h5>Internet Para Todos</h5>
      </div> -->
        <div class="menu">
             <a class="linkMenu" routerLink="/main/report">Reportes</a>
             <a class="linkMenu" routerLink="/main/bulkloadproject">Carga masiva de Proyectos</a>         
        </div>
    </mat-toolbar-row>
</mat-toolbar>
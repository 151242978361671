import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ReportGeneratorMsService } from 'src/app/services/report/report-generator-ms.service';
import { saveAs } from 'file-saver';

interface EstadoProceso {
  mensaje: string;
  color: string;
  icon: string;
  modelProgressBar: string;
  valueProgressBar: string;
  colorProgressBar: string;
}

// Constantes de progress Bar para procesamiento de archivos

const inicio: EstadoProceso = {
  mensaje: 'Se debe seleccionar un archivo',
  color: '#3f51b5',
  icon: 'warning',
  modelProgressBar: 'determinate',
  valueProgressBar: '100',
  colorProgressBar: 'primary',
};

const enProceso: EstadoProceso = {
  mensaje: 'El archivo está siendo procesado, espere un momento ...',
  color: '#3f51b5',
  icon: 'science',
  modelProgressBar: 'query',
  valueProgressBar: '100',
  colorProgressBar: 'accent',
};
const doneProceso: EstadoProceso = {
  mensaje: 'El archivo se procesó correctamente',
  color: '#45af7b',
  icon: 'done',
  modelProgressBar: 'determinate',
  valueProgressBar: '100',
  colorProgressBar: 'accent',
};
const errorProceso: EstadoProceso = {
  mensaje: 'Hubo un problema en el procesamiento del archivo',
  color: 'red',
  icon: 'error_outline',
  modelProgressBar: 'determinate',
  valueProgressBar: '100',
  colorProgressBar: 'warn',
};

@Component({
  selector: 'app-report-form',
  templateUrl: './report-form.component.html',
  styleUrls: ['./report-form.component.css']
})
export class ReportFormComponent implements OnInit {


  fileToUpload: File;
  entelReport = false;
  movistarReport = false;
  entelCoverage = false;
  tdpCoverage = false;
  installation = false;
  estadoProceso: EstadoProceso = inicio;
  validacionProcesamiento = false;

  constructor(private reportMS: ReportGeneratorMsService) { }

  ngOnInit(): void { }

  // File Interaction
  // tslint:disable-next-line: typedef
  fileChange(element) {
    this.fileToUpload = element.target.files[0];
    this.validarProcesamiento();
  }

  validarProcesamiento() {
    this.validacionProcesamiento = this.fileToUpload === undefined ? false : true;

  }

  obtenerMensaje(): string {
    let mensaje = 'Seleccione un archivo ...';
    if (this.fileToUpload !== undefined) {
      mensaje = this.fileToUpload.name;
    }
    return mensaje;
  }

  procesarArchivo() {
    const formData: FormData = new FormData();
    formData.append('file', this.fileToUpload);
    formData.append('entelReport', String(this.entelReport));
    formData.append('movistarReport', String(this.movistarReport));
    formData.append('entelCoverage', String(this.entelCoverage));
    formData.append('tdpCoverage', String(this.tdpCoverage));
    formData.append('installation', String(this.installation));    
    this.estadoProceso = enProceso;
    this.validacionProcesamiento = false;
    this.reportMS.generateReports(formData).subscribe(data => {
      this.estadoProceso = doneProceso;
      this.validarProcesamiento();
      var hoy = new Date();
      var fecha = hoy.getDate() + '-' + ( hoy.getMonth() + 1 ) + '-' + hoy.getFullYear();            
      saveAs(data, 'reporte_' + fecha + '.zip');       
    }, error => {
      this.estadoProceso = errorProceso;
      this.validarProcesamiento();
    });
  }

}
